import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Title } from '../Typography';
import type { ICountryItem, CountrySelectorType } from './types';

import Button from './components/Button';
import DropDown from './components/DropDown';

export const CountrySelector = ({ data }: CountrySelectorType) => {
  // extracting first content to populate the initial state
  const firstData = data?.countrySelectorList?.[0];
  const defaultCountry = firstData?.country;
  const defaultLanguage = firstData?.language?.[0]?.displayName;
  const initialFlagIcon = firstData?.countryFlag?.asset?.[0].url;
  const initialLangList = firstData?.language;
  // refs to container to track outside clicks
  const countrySelectorRef = useRef<HTMLDivElement>(null);
  const languageSelectorRef = useRef<HTMLDivElement>(null);

  // controls to hide or show the language section
  const [showLanguagesSection, setShowLanguagesSection] = useState<boolean>(false);
  // controls when to show drop downs according to use interactions
  const [showCountriesDropDown, setShowCountriesDropDown] = useState<boolean>(false);
  const [showLanguagesDropDown, setShowLanguagesDropDown] = useState<boolean>(false);
  // sets the market link
  const [link, setLink] = useState<string>('');
  // controls the value of the current chosen language/locale
  const [countryFlag, setCountryFlag] = useState<string>(initialFlagIcon || '');
  const [selectedCountry, selectCountry] = useState<string>(defaultCountry || '');
  const [selectedLanguage, selectLanguage] = useState<string>(defaultLanguage || '');
  const [langList, setLangList] = useState<ICountryItem['language']>(initialLangList || []);

  const updateCountry = (event: React.MouseEvent<HTMLButtonElement>) => {
    const countryObject = data?.countrySelectorList?.find(
      (countryObj) => countryObj.id === event.currentTarget.id,
    );
    selectCountry(countryObject?.country || event.currentTarget.innerText);
    setCountryFlag(countryObject?.countryFlag?.asset?.[0]?.url || '');
    setShowCountriesDropDown(false);
    if (countryObject?.language) {
      if (countryObject?.language?.length && countryObject.language.length > 1) {
        setShowLanguagesSection(true);
      } else {
        setShowLanguagesSection(false);
      }
      setLangList(countryObject.language);
      selectLanguage(countryObject.language[0]?.displayName);
      setLink(countryObject.language[0]?.url);
    }
  };

  const updateLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = event.currentTarget;
    const language = langList?.find((lanObj) => lanObj.id === id);
    if (language) {
      selectLanguage(language?.displayName);
      setLink(language?.url);
    }
    setShowLanguagesDropDown(false);
  };

  useOnClickOutside(countrySelectorRef, (event: any) => {
    if (event.target.nodeName === 'BUTTON' && event.target.id !== 'language') return;
    if (event.target.nodeName !== 'BUTTON') setShowCountriesDropDown(false);
  });
  useOnClickOutside(languageSelectorRef, (event: any) => {
    if (event.target.nodeName === 'BUTTON' && event.target.id !== 'country') return;
    if (event.target.nodeName !== 'BUTTON') setShowLanguagesDropDown(false);
  });

  return (
    <div className="p-0 max-w-none">
      <Title tag="div" type="xs" className="md:mb-30 sm:mb-10 text-gray-500">
        {data?.title}
      </Title>

      <div className="md:w-300 sm:w-full inline-block align-top md:mr-6 sm:mr-0 relative">
        <DropDown
          label={data?.countryLabel}
          hidden={false}
          withImage
          onButtonClick={() => {
            setShowCountriesDropDown(!showCountriesDropDown);
          }}
          selectedItem={selectedCountry}
          selectedImage={countryFlag}
          showDropDown={showCountriesDropDown}
          data={data?.countrySelectorList}
          update={updateCountry}
        />
      </div>
      <div className="md:w-300 sm:w-full inline-block align-top md:mr-6 sm:mr-0 relative">
        {showLanguagesSection && (
          <DropDown
            label={data?.languageLabel}
            withImage={false}
            hidden={!showLanguagesSection}
            onButtonClick={() => {
              setShowLanguagesDropDown(!showLanguagesDropDown);
            }}
            selectedItem={selectedLanguage}
            showDropDown={showLanguagesDropDown}
            data={langList}
            update={updateLanguage}
          />
        )}
      </div>
      <div className="sm:text-center md:text-left md:w-300 sm:w-full inline-block align-top md:mr-6 sm:mr-0 relative">
        <Button link={link} label={data?.textOnButton} />
      </div>
    </div>
  );
};
