import { Text as Paragraph, Title } from '../../Typography';
import { Link } from '../../Link';
import { FooterNavType } from '../types';
import { ReactNode } from 'react';
import type { PathTranslations } from 'service/localization/getPathTranslations';
import { LinksService } from 'service/links/linksService';

const getMobileNavData = ({
  footerNav,
  onClickHandler,
  pathTranslations,
  socialMediaLinks,
}: {
  pathTranslations: PathTranslations;
  socialMediaLinks?: ReactNode;
  onClickHandler: (target: string, linksTitle?: string) => void;
  footerNav?: FooterNavType[];
}) => {
  return footerNav
    ?.filter((navlist: FooterNavType) => navlist.footerNavType === 'MainNav')
    .map((navlistlink: FooterNavType) => {
      const bodyTempl = navlistlink?.links?.map((mobileLink: any) => (
        <Paragraph tag="p" type="md" key={mobileLink.id}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span
            className="block mb-2.5"
            onClick={() =>
              onClickHandler(mobileLink?.displayName || '', navlistlink.cmsTitle || '')
            }
          >
            <Link
              href={
                LinksService.getInternalLinks({ item: mobileLink, pathTranslations }) ||
                mobileLink?.url ||
                '/'
              }
              target={mobileLink?.openInNewTab ? '_blank' : '_self'}
            >
              {mobileLink?.displayName}
            </Link>
          </span>
        </Paragraph>
      ));
      return {
        title: (
          <Title tag="div" type="xs" className="text-gray-500" key={navlistlink.id}>
            {navlistlink.cmsTitle}
          </Title>
        ),
        body: (
          <div className="mb-5" key={navlistlink.id}>
            {navlistlink.socialMediaLink ? socialMediaLinks : bodyTempl}
          </div>
        ),
        id: navlistlink.cmsTitle,
      };
    });
};

export default getMobileNavData;
