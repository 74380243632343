import { Text as Paragraph } from '../../Typography';
import NextImage from '../../NextImage';
import { Link } from '../../Link';
import { SocialMedia } from '../types';

interface ISocialMediaLinks {
  socialMedia?: SocialMedia[];
  onClickHandler: (target: string, linksTitle?: string) => void;
}

const SocialMediaLinks = ({ onClickHandler, socialMedia }: ISocialMediaLinks) => {
  if (!socialMedia) {
    return null;
  }
  return (
    <>
      {socialMedia.map((social: SocialMedia) => {
        const socialMPosition =
          (social?.socialSharePosition && social.socialSharePosition.indexOf('Footer') > -1) ||
          false;
        if (socialMPosition) {
          return (
            <li
              className="mb-5 list-none"
              key={social.id}
              onClick={() => onClickHandler(social?.name || '', 'social')}
            >
              <Paragraph tag="p" type="md">
                {social?.assetsComponents?.[0]?.asset?.[0]?.url && (
                  <NextImage
                    src={social?.assetsComponents?.[0]?.asset?.[0]?.url || ''}
                    mimeType={String(social?.assetsComponents?.[0]?.asset?.[0]?.mimeType)}
                    alt={social?.name}
                    width={18}
                    height={18}
                    className="mr-2 inline-block"
                  />
                )}
                <Link href={social?.url || ''} target="_blank">
                  {social?.name}
                </Link>
              </Paragraph>
            </li>
          );
        }
        return false;
      })}
    </>
  );
};

export default SocialMediaLinks;
