import { Text as Paragraph } from '../../Typography';
import { SelectBoxArrowUp } from '@icons/SelectBoxArrowUp';
import { SelectBoxArrowDown } from '@icons/SelectBoxArrowDown';
import { ICountryItem, ILanguageItem } from '../types';
import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Ref } from '../../Cards/components/ContentWrapper';
import SelectedItem from './SelectedItem';
import CountryItem from './CountryItem';
import LanguageItem from './LanguageItem';

interface IDropDownProps {
  showDropDown: boolean;
  selectedItem?: string;
  selectedImage?: string;
  onButtonClick: () => void;
  label?: string;
  hidden: boolean;
  withImage?: boolean;
  data?: ILanguageItem[] | ICountryItem[];
  update: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DropDown = forwardRef<Ref, IDropDownProps>(
  (
    { update, label, hidden, onButtonClick, selectedItem, selectedImage, showDropDown, data },
    ref,
  ) => {
    return (
      <>
        <Paragraph
          tag="span"
          type="lead"
          className={clsx(
            'text-gray-500  inline-block w-full pb-5 form-text',
            hidden && 'hidden',
            !selectedImage && 'sm:mt-10 xl:mt-0',
          )}
        >
          {label}
        </Paragraph>
        <button
          onClick={onButtonClick}
          className="px-5 font-momentstext tracking-wider text-sm text-darkgray w-full border border-grayBorder rounded-2xl flex items-center justify-between h-12"
        >
          <SelectedItem selectedItem={selectedItem} selectedImage={selectedImage} />
          <>{showDropDown ? <SelectBoxArrowUp /> : <SelectBoxArrowDown />}</>
        </button>
        <ul
          className={clsx(
            'absolute z-10 rounded-2xl w-full border  border-grayBorder p-0 bg-light-brown max-h-countrySelectorHeight overflow-auto mt-2.5 no-scrollbar',
            !showDropDown && 'hidden',
          )}
        >
          {data?.map((item: ICountryItem | ILanguageItem) => (
            <div
              ref={ref}
              key={`show-dropdown-${item.id}`}
              className={showDropDown ? '' : 'hidden'}
            >
              <li
                className="border-b border-grayBorder m-0 min-h-[60px] first:rounded-t-2xl last:rounded-b-2xl last:border-b-0 hover:bg-dark-brown hover:border-gray-500"
                key={`${item?.id}`}
              >
                {selectedImage ? (
                  <CountryItem country={item as ICountryItem} updateCountry={update} />
                ) : (
                  <LanguageItem language={item as ILanguageItem} updateLanguage={update} />
                )}
              </li>
            </div>
          ))}
        </ul>
      </>
    );
  },
);

export default DropDown;
