import { GridItem, GridRow } from '../../Grid';
import { CountrySelector } from '../../CountrySelector';
import { Text as Paragraph, Title } from '../../Typography';
import { DownloadApps } from '../../../types/Footer';
import { ICountrySelector } from '../../CountrySelector/types';
import AppStoreLink from './AppStoreLink';

interface IAppStore {
  countrySelectorData: ICountrySelector;
  download?: DownloadApps;
  trackingEventsAppStore?: (target: string) => void;
}

const AppStore = ({ countrySelectorData, download, trackingEventsAppStore }: IAppStore) => {
  const handleAppStore = (device: string) => {
    if (trackingEventsAppStore) {
      trackingEventsAppStore(device);
    }
  };

  return (
    <GridRow columns={16}>
      <GridItem colSpan={16}>
        <div className="flex flex-col md:flex-row md:border-t border-cake md:pt-20 sm:pt-10">
          <CountrySelector data={countrySelectorData} />
          <div className="md:w-1/2 sm:w-full inline-block align-top sm:mt-10 md:mt-0">
            {(download?.appStoreLink || download?.googlePlayLink) && (
              <>
                <Title tag="div" type="sm" className="text-gray-500">
                  {download?.heading}
                </Title>
                <Paragraph className="pt-5 pb-8" tag="p" type="md">
                  {download?.description}
                </Paragraph>
                <AppStoreLink
                  handleAppStore={handleAppStore}
                  appStoreComponent={download?.appStoreComponent}
                  appStoreLink={download?.appStoreLink}
                  device="ios"
                />
                <AppStoreLink
                  handleAppStore={handleAppStore}
                  appStoreComponent={download?.googlePlayAssetComponent}
                  appStoreLink={download?.googlePlayLink}
                  device="android"
                />
              </>
            )}
          </div>
        </div>
      </GridItem>
    </GridRow>
  );
};

export default AppStore;
