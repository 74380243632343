import { GridItem, GridRow } from '../../Grid';
import NextImage from '../../NextImage';
import { Seal } from '../types';

interface ISeals {
  seals: Seal[];
}

const Seals = ({ seals }: ISeals) => {
  return (
    <GridRow columns={16}>
      <GridItem colSpan={16}>
        <div className="border-t border-cake md:pt-20 md:mt-20 sm:pt-10 sm:mt-5 w-full md:w-auto grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-6">
          {seals.map((footerSeals: Seal) => (
            <div
              className="w-full flex items-center flex-col text-center justify-end"
              key={`seal-${footerSeals?.id}`}
            >
              <a
                href={footerSeals?.url}
                target="_blank"
                rel="noreferrer"
                className="flex flex-col items-center"
              >
                {footerSeals?.assetsComponents?.asset?.[0]?.url && (
                  <NextImage
                    src={footerSeals?.assetsComponents?.asset?.[0]?.url}
                    mimeType={String(footerSeals?.assetsComponents?.asset?.[0]?.mimeType)}
                    height={68}
                    width={68}
                    className="w-[68px] mb-[12px]"
                    alt={`${footerSeals.name} logo`}
                  />
                )}
                <span>{footerSeals.name}</span>
              </a>
            </div>
          ))}
        </div>
      </GridItem>
    </GridRow>
  );
};

export default Seals;
